import { Link } from "remix";

export function Logo({
  className,
  width = "100%",
}: {
  className?: string;
  width?: string;
}) {
  return (
    <Link to="/" aria-label="JSON Hero homepage" className="w-40">
      <img className="object-contain h-8 w-8 m-2" src="https://www.classdojo.com/favicon.svg" alt="JSON Hero logo" />
    </Link>
  );
}
